import React from 'react';
import { Quick, LoadingClass } from 'utils/common';
import { graphql, Link } from 'gatsby';
import Layout from 'components/layout';
import Img from 'gatsby-image';

const Loading = new LoadingClass();
export default class Home extends React.Component {
	componentDidMount() {
		Loading.dataLoaded = true;
		Loading.Load();
		LandingFunction.init();

		if (typeof document !== `undefined`) document.body.id = 'landing';
	}
	componentWillUnmount() {
		LandingFunction.exit();
		Loading.Exit();
	}
	render() {
		Loading.MinTimeLoad();
		const data = this.props.data;
		const ImageProps = {
			style: {
				margin: '0',
				width: '100%',
				height: '100%'
			}
		};
		return (
			<Layout titleText="" mainId="landing" mainClass="fitheight">
				<section id="Background">
					{data.index.frontmatter.imagelist.map((set, id) => {
						return (
							<div key={id}>
								{set.image.childImageSharp && (
									<Img {...ImageProps} fluid={set.image.childImageSharp.fluid} alt={set.title} />
								)}
							</div>
						);
					})}
				</section>
				<div className="mobile_landing">
					<span className="malt_logo">
						<span>
							MALT
							<br />
							Studio
						</span>
					</span>
					<Link className="enter_site" to="/works">
						<span>
							<span>enter site</span>
							<span>></span>
						</span>
					</Link>
				</div>
				<div className="home_footer">
					<div>
						{data.setting.frontmatter.contact.email && (
							<a
								className=""
								href={`mailto:${data.setting.frontmatter.contact.email}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{data.setting.frontmatter.contact.email}
							</a>
						)}

						{data.setting.frontmatter.contact.instagram && (
							<a
								href={`https://www.instagram.com/${data.setting.frontmatter.contact.instagram}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{data.setting.frontmatter.contact.instagram}
							</a>
						)}
					</div>
					<div>
						{data.setting.frontmatter.contact.address && (
							<a
								className=""
								href={`${data.setting.frontmatter.contact.address_link}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{data.setting.frontmatter.contact.address}
							</a>
						)}
					</div>
				</div>
			</Layout>
		);
	}
}

const LandingFunction = {
	initialDelay: 2000,
	bgSpeed: 5000,
	init: () => {
		const allSlides = Quick.qSAll('#Background > div');
		allSlides[0].classList.add('active');
		Quick.qS('#Background').classList.add('loaded');
		if (allSlides.length > 1) {
			setTimeout(LandingFunction.bgLoop, LandingFunction.initialDelay);
		}
	},
	exit: () => {
		Quick.qS('#Background').classList.remove('loaded');
	},
	bgAnimFrame: null,
	bgAnimStart: null,
	bgAnimPlaying: true,
	bgInterval: null,
	bgActive: 0,
	bgLoop: () => {
		const allSlides = Quick.qSAll('#Background > div');

		const loopBG = () => {
			allSlides.forEach((slide) => {
				slide.classList.remove('active');
			});
			let nextSlide = LandingFunction.bgActive + 1;

			if (nextSlide > allSlides.length - 1) {
				nextSlide = 0;
			}
			LandingFunction.bgActive = nextSlide;

			allSlides[nextSlide].classList.add('active');
		};

		if (LandingFunction.bgInterval !== null) {
			clearInterval(LandingFunction.bgInterval);
		}

		LandingFunction.bgInterval = setInterval(loopBG, LandingFunction.bgSpeed);
	}
};

export const query = graphql`
	query {
		index: markdownRemark(frontmatter: { issetting: { eq: true }, contenttype: { eq: "landing" } }) {
			frontmatter {
				issetting
				contenttype
				imagelist {
					title
					image {
						childImageSharp {
							fluid(maxWidth: 1920) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			}
		}
		setting: markdownRemark(frontmatter: { issetting: { eq: true }, contenttype: { eq: "general_setting" } }) {
			frontmatter {
				title
				web_name
				header_title
				seo {
					seo_keywords
					seo_shortdesc
				}
				contact {
					email
					instagram
					address
					address_link
				}
			}
		}
	}
`;
